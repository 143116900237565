/* eslint-env browser, jquery */
/* global _ */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  var pageProtocol = $.url('protocol');
  var pagePort = $.url('port') === '80' ? '' : (':' + $.url('port'));
  var pageHost = $.url('hostname');
  var pageBaseUrl = pageProtocol + '://' + pageHost + pagePort;
  var explicitBackendUrl = $.url('?backend_url');

  function getBackendUrl() {
    if (explicitBackendUrl) {
      return explicitBackendUrl;
    }

    if (isGoogleBot()) {
      return $.ajax({
        type: "GET",
        url: "/hosts2.php?r=" + encodeURIComponent(nasos.utils.stringHashCode(window.location.href)),
        async: false
      }).responseText;
    }

    var backendUrls = 'https://i200.123muza.com,https://i201.123muza.com,https://i202.123muza.com'.split(',');
    // Added current URL hash to determine backend URL index, because Google Crawler has deterministic Math.random()
    // function, and mostly it's value is zero. Current implementation still will be fully random for users and other
    // Crawlers, and different for different URLs for Google Crawler
    var currentUrlHashCode = nasos.utils.stringHashCode(window.location.href);
    var backendUrlIndex = (Math.abs(currentUrlHashCode) + _.random(backendUrls.length - 1)) % backendUrls.length;
    return backendUrls[backendUrlIndex];
  }

  function escapeQuery(query) {
    return query.replace(/\//g, ' ').replace(/\./g, ' ');
  }

  function buildExplicitBackendUrlParameterIfNeeded() {
    if (!explicitBackendUrl) {
      return '';
    }

    return '?backend_url=' + encodeURIComponent(explicitBackendUrl);
  }

  function isGoogleBot() {
    var userAgent = navigator.userAgent || '';
    return userAgent.includes('APIs-Google')
        || userAgent.includes('AdsBot-Google')
        || userAgent.includes('Googlebot')
        || userAgent.includes('Mediapartners-Google')
        || userAgent.includes('FeedFetcher-Google')
        || userAgent.includes('Google-Read-Aloud')
        || userAgent.includes('DuplexWeb-Google')
        || userAgent.includes('Google Favicon');
  }

  var searchByTextUrlPrefix = 'track';
  var searchByArtistUrlPrefix = 'artist';
  var searchBySongUrlPrefix = 'track';
  var playlistUrlPrefix = 'playlist';

  window.nasos.env = {

    backendUrl: getBackendUrl(),

    pageBaseUrl: pageBaseUrl,

    buildSearchByTextUrl: function (query) {
      return pageBaseUrl + '/' + searchByTextUrlPrefix + '/' + encodeURIComponent(escapeQuery(query))
          + buildExplicitBackendUrlParameterIfNeeded();
    },

    buildSearchByArtistUrl: function (artist) {
      return pageBaseUrl + '/' + searchByArtistUrlPrefix + '/' + encodeURIComponent(escapeQuery(artist))
          + buildExplicitBackendUrlParameterIfNeeded();
    },

    buildSearchBySongUrl: function (artist) {
      return pageBaseUrl + '/' + searchBySongUrlPrefix + '/' + encodeURIComponent(escapeQuery(artist))
          + buildExplicitBackendUrlParameterIfNeeded();
    },

    buildSearchUrlByType: function (query, type) {
      return (type === 'byText')
          ? this.buildSearchByTextUrl(query)
          : (type === 'byArtist')
              ? this.buildSearchByArtistUrl(query)
              : (type === 'bySong')
                  ? this.buildSearchBySongUrl(query)
                  : this.buildSearchByTextUrl(query);
    },

    buildPlaylistUrl: function (id) {
      return pageBaseUrl + '/' + playlistUrlPrefix + '/' + encodeURIComponent(id)
          + buildExplicitBackendUrlParameterIfNeeded();
    },

    getSearchTextFromUrl: function () {
      var encodedQuery = $.url('2') || '';
      return decodeURIComponent(encodedQuery) || '';
    },

    getArtistFromUrl: function () {
      var encodedQuery = $.url('2') || '';
      return decodeURIComponent(encodedQuery) || '';
    },

    getSongFromUrl: function () {
      var encodedQuery = $.url('2') || '';
      return decodeURIComponent(encodedQuery) || '';
    },

    getQueryFromUrl: function () {
      return this.getSearchTextFromUrl() || this.getArtistFromUrl() || this.getSongFromUrl();
    },

    getPlaylistIdFromUrl: function () {
      var id = $.url('2') || '';
      return decodeURIComponent(id) || '';
    },

    isPlaylistPage: function () {
      return $.url('1') === playlistUrlPrefix;
    },

    isSearchByTextPage: function () {
      return $.url('1') === searchByTextUrlPrefix;
    },

    isSearchByArtistPage: function () {
      return $.url('1') === searchByArtistUrlPrefix;
    },

    isSearchBySongPage: function () {
      return $.url('1') === searchBySongUrlPrefix;
    },

    getSearchPageType: function () {
      return this.isSearchByTextPage()
          ? 'byText'
          : this.isSearchByArtistPage()
              ? 'byArtist'
              : this.isSearchBySongPage()
                  ? 'bySong'
                  : 'byText';
    },

    isSearchBySongUrlPrefixEnabled: function () {
      return !!searchBySongUrlPrefix;
    }

  };
})();
