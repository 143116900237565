/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function() {
  'use strict';

  window.nasos.search = window.nasos.search || {};
  window.nasos.search.lastQueriesWidget = {

    init: function() {
      var lastQueriesEl = $('#n-recent-search');

      $.ajax({
        url: window.nasos.env.backendUrl + '/api/song/search/recent' +
        '?origin=' + encodeURIComponent('textmp3.ru'),
        dataType: 'jsonp',

        success: function(queries) {
          $(queries).each(function() {
            lastQueriesEl.append(
              $('<span class="mdl-chip"/>').append(
                $('<a class="mdl-chip__text mdl-color-text--black"/>')
                  .text(this.query)
                  .attr('href', window.nasos.env.buildSearchUrlByType(this.query, this.type))
              )
            );
            lastQueriesEl.append($('<span/>').text(' '));
          });

          window.componentHandler.upgradeAllRegistered();
        }
      });
    }

  };
})();
