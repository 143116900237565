/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  nasos.page = nasos.page || {

    init: function () {
      nasos.metrics.reachSessionParameters({displayMode: this.getDisplayMode()});

      var pageCategory = this.getPageCategoryIfAny();

      if (pageCategory) {
        nasos.page.setTitle(pageCategory.title);
        nasos.page.setDescription(pageCategory.title);
        nasos.page.setSongListCaption(pageCategory.title);

      } else if (!nasos.env.isPlaylistPage()) {
        // TODO: think about different type of Page title and description, based on Page type: search|song|artist
        var queryFromUrl = nasos.env.getQueryFromUrl();
        nasos.page.setTitle(queryFromUrl);
        nasos.page.setDescription(queryFromUrl);
        nasos.page.setSongListCaption(queryFromUrl);
      }
    },

    getDisplayMode: function () {
      if (!window.matchMedia) {
        return 'unknown';
      }

      return window.matchMedia('(display-mode: standalone)').matches
          ? 'standalone'
          : 'not-standalone';
    },

    setTitle: function (text) {
      if (!text) {
        return;
      }

      var title = 'Скачать {N_QUERY} бесплатно mp3 на Textmp3.ru'.replace('{N_QUERY}', text);

      $('title').text(title);
      $('meta[name=\'apple-mobile-web-app-title\']').attr('content', title);
    },

    setDescription: function (text) {
      if (!text) {
        return;
      }

      var description = '{N_QUERY}: скачать бесплатно на сайте textmp3.ru'.replace('{N_QUERY}', text);

      $('meta[name=\'description\']').attr('content', description);
    },

    setDescriptionWith1Song: function (song) {
      var description = 'Скачать {N_SONG_ARTIST_0} - {N_SONG_TITLE_0} на телефон или слушать онлайн'
      .replace('{N_SONG_ARTIST_0}', song.artist)
      .replace('{N_SONG_TITLE_0}', song.title);

      $('meta[name=\'description\']').attr('content', description);
    },

    setDescriptionWith2Songs: function (song0, song1) {
      var description = 'Скачивай {N_SONG_ARTIST_0} - {N_SONG_TITLE_0}, {N_SONG_ARTIST_1} - {N_SONG_TITLE_1} на телефон в формате mp3 абсолютно бесплатно или слушай онлайн'
      .replace('{N_SONG_ARTIST_0}', song0.artist)
      .replace('{N_SONG_TITLE_0}', song0.title)
      .replace('{N_SONG_ARTIST_1}', song1.artist)
      .replace('{N_SONG_TITLE_1}', song1.title);

      $('meta[name=\'description\']').attr('content', description);
    },

    setSongListCaption: function (captionQuery) {
      if (!captionQuery) {
        $('#n-song-list-caption-row').remove();
        return;
      }

      var captionText = 'Скачать {N_QUERY} или слушать онлайн:'.replace('{N_QUERY}', function () {
        return '\'' + captionQuery + '\''
      });
      $('#n-song-list-caption').html(captionText);
    },

    getPageCategoryIfAny: function () {
      if (nasos.env.isPlaylistPage()) {
        return _.findWhere(this.categories, {type: 'playlist', id: nasos.env.getPlaylistIdFromUrl()});
      } else {
        return _.findWhere(this.categories, {type: 'search', query: nasos.env.getSearchTextFromUrl()});
      }
    },

    categories: [
      {title: 'Русские хиты', type: 'playlist', id: 'audio_playlist-147845620_7'},
      {title: 'Мировые хиты', type: 'playlist', id: 'audio_playlist-147845620_8'},
      {title: 'Музыка в машину', type: 'playlist', id: 'audio_playlist-117742128_84942328'},
      {title: 'Дискотека 80-90 х', type: 'playlist', id: 'audio_playlist-20261755_22606634'},
      {title: 'Музыка 2000-х', type: 'search', query: 'Музыка 2000-х'},
      {title: 'Рингтон на звонок', type: 'playlist', id: 'audio_playlist-18939727_4745443'},
      {title: 'Классическая музыка', type: 'search', query: 'Classical Music'},
      {title: 'Русский шансон', type: 'search', query: 'русский шансон'},
      {title: 'Евровидение 2019', type: 'search', query: 'Евровидение 2019'},
      {title: 'Детские песни', type: 'search', query: 'детские песни'},
      {title: 'Аудиосказки', type: 'search', query: 'аудиосказки'},
      {title: 'Прикольные рингтоны', type: 'search', query: 'Прикольные рингтоны'},
      {title: 'Русские народные сказки', type: 'search', query: 'Русские народные сказки'},
      {title: 'Любимые грустные песни', type: 'playlist', id: 'audio_playlist-127481200_84726349'},
      {title: 'Романтические песни', type: 'playlist', id: 'audio_playlist-127481200_85393958'},
      {title: 'Песни про любовь', type: 'search', query: 'Песни про любовь'},
      {title: 'Песни из советских фильмов', type: 'search', query: 'Песни из советских фильмов'},
      {title: 'Хиты радиостанций', type: 'playlist', id: 'audio_playlist-127481200_85393931'},
      {title: 'Узбекские', type: 'search', query: 'Узбекские Песни'},
      {title: 'Азербайджанские песни', type: 'search', query: 'Азербайджанская'},
      {title: 'Даргинская', type: 'search', query: 'Даргинская'},
      {title: 'Молдавские', type: 'search', query: 'Молдавские'},
      {title: 'Казачьи песни', type: 'search', query: 'Казачьи песни'},
      {title: 'Татарская музыка', type: 'search', query: 'Татарская музыка'},
      {title: 'Украинские свадебные', type: 'search', query: 'Украинские свадебные'},
      {title: 'Чувашские', type: 'search', query: 'чувашские'},
      {title: 'Казахские', type: 'search', query: 'Казахские Песни'},
      {title: 'Индийская музыка', type: 'search', query: 'Индийская'},
      {title: 'Лезгинка', type: 'search', query: 'Лезгинка'},
      {title: 'Кавказские песни', type: 'search', query: 'Кавказские'},
      {title: 'Башкирские', type: 'search', query: 'Башкирские'},
      {title: 'Крымско-татарские песни', type: 'search', query: 'Крымско-татарские песни'},
      {title: 'Арабские', type: 'search', query: 'Арабские'},
      {title: 'Удмуртские', type: 'search', query: 'Удмуртские'},
      {title: 'Кумыкские', type: 'search', query: 'Кумыкские'},
      {title: 'Аварские песни', type: 'search', query: 'Аварские песни'},
      {title: 'Таджикские', type: 'search', query: 'Таджикские песни'},
      {title: 'Уйгурская музыка', type: 'search', query: 'Уйгурская музыка'},
      {title: 'Езидские', type: 'search', query: 'Езидские песни'},
      {title: 'Турецкие песни', type: 'search', query: 'Турецкие песни'},
      {title: 'Чеченские', type: 'search', query: 'Чеченские песни'},
      {title: 'Армянские песни', type: 'search', query: 'Армянские песни'},
      {title: 'Цыганские песни', type: 'search', query: 'Цыганские песни'},
      {title: 'Кубанские казаки', type: 'search', query: 'Кубанские казаки'},
      {title: 'Грузинские песни', type: 'search', query: 'Грузинские песни'},
      {title: 'Итальянская музыка', type: 'playlist', id: 'audio_playlist-154156662_47'},
      {title: 'Иранская музыка', type: 'search', query: 'Иранская музыка'},
      {title: 'Французские Песни', type: 'search', query: 'французские песни'},
      {title: 'Болгарские народные песни', type: 'search', query: 'Болгарские народные песни'},
      {title: 'Украинские народные песни', type: 'search', query: 'Украинские народные песни'}
    ]

  };
})();
