/* eslint-env browser */
/* eslint-disable max-len */
(function() {
  'use strict';

  window.nasos.bann = window.nasos.bann || {};

  var artistRules = [];
  var titleRules = [];

  // RULES START

  artistRules.push(/^.*Валик.+Барвенко.*$/im);
  titleRules.push(/^.*$/im);

  artistRules.push(/^.*Ilya.+Sapranidi.*$/im);
  titleRules.push(/^.*This.+I.+Love.*$/im);

  artistRules.push(/^.*Gabbar.+Is.+Back.*$/im);
  titleRules.push(/^.*$/im);

  artistRules.push(/^.*$/im);
  titleRules.push(/^.*Aao.+Raja.*$/im);

  // RULES END

  window.nasos.bann.artistRules = artistRules;
  window.nasos.bann.titleRules = titleRules;
  window.nasos.bann.queryRules = [
    'laura pausini',
    'jovanotti',
    'negramaro',
    'eros ramazzotti',
    'elisa',
    'tiziano ferro',
    'fabri fibra',
    'zucchero',
    'fiorella mannoia',
    'mahomood',
    'vasco rossi',
    'francesco de gregori',
    'antonello venditti',
    'nek',
    'ligabue',
    'gabbar is back - aao raja',
    'gabbar is back',
    'aao raja'
  ];
})();
