/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {

  'use strict';

  window.nasos.metrics = window.nasos.metrics || {

    reachGoal: function (goal, parameters) {
      try {
        // console.log('reachGoal', arguments);
        ym('61050814', 'reachGoal', goal, parameters);

      } catch (e) {
        // ignore
      }
    },

    reachSessionParameters: function (parameters) {
      try {
        // console.log('reachSessionParameters', arguments);
        ym('61050814', 'params', parameters);

      } catch (e) {
        // ignore
      }
    }
  };

})();
