/* eslint-disable */
(function () {
  'use strict';

  try {

    var rotatorPlaceNames = [
      'Top',
      'Middle',
      'Bottom',
      'VK-Message',
      'Brand',
      'ClickUnder',
      'PushNotification',
      'DownloadButton'
    ];

    for (let i = 0; i < rotatorPlaceNames.length; i++) {
      const rotatorPlaceName = rotatorPlaceNames[i];

      const rotatorPlaceCodeSnippetHtml =
        '<div id="rotator-place-'
        + rotatorPlaceName
        + '"></div>\n'
        + '<script src="https://ro-tator.info/api/script/sites/'
        + 'textmp3'
        +'/places/'
        + rotatorPlaceName
        + '/rotator-dynamic.js"></script>';

      $('#n-rek-banner-' + rotatorPlaceName).html(rotatorPlaceCodeSnippetHtml);
    }

  } catch (ex) {
  }

})();
